export default {
  "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carlos Caravela®"])},
  "callToActionLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://form.typeform.com/to/hJ6fwxBn"])},
  "header": {
    "hero": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("companyName")])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre nós"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificações"])},
    "callToAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue-Sat 17.30 - 24.00"])},
    "reservationRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomenda-se a reserva antecipada"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["030 / 852 26 60"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "footer": {
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressão"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacidade"])},
    "disclaimer": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["© 1997 - 2023 ", _linked("companyName"), " All rights reserved"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Made with ❤️   in Berlin"])}
  },
  "hero": {
    "title": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("companyName")])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma amostra de Portugal no Coração de Berlim desde 1997"])},
    "callToAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver Menu"])}
  },
  "reviews": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificações"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O que os nossos convidados dizem sobre nós"])}
  },
  "features": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O que oferecemos aos nossos convidados"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospitalidade Portuguesa e Cozinha Autêntica"])},
    "items": [
      {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fresco"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["peixe fresco diariamente,e espesialidades portuguesas"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["emojione-monotone:pot-of-food"])}
      },
      {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caseira"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tapas caseiras"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fluent:food-24-filled"])}
      },
      {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autêntico"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uma variadede de vinhos potugueses de qualidade"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bxs:wine"])}
      }
    ]
  },
  "404": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404"])},
    "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não encontramos a sua página"])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para o inventar, veja este gato giro :) fonte da imagem"])},
    "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar à página inicial"])}
  }
}