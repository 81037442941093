<template>
  <div class="z-50">
    <header class="text-gray-600 body-font bg-background-accent capitalize font-normal">
      <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <router-link :to="{name: 'Home', hash: '#hero'}" class="cursor-pointer">
          <img src="../assets/logo/logo.png" class="h-12">
        </router-link>
        <nav class="md:ml-auto flex flex-wrap items-center text-base justify-center flex flex-row
                    gap-6">
          <div>
            <span>{{ $t('header.time') }}</span>
            <br/>
            <span class="text-xs normal-case">{{ $t('header.reservationRecommendation') }}</span>
          </div>
          <a class="text-primary-dark animate-pulse" :href="'tel:' + $t('header.phone')">
            {{ $t('header.phone') }}</a>
          <span class="flex flex-row justify-center align-center gap-1 text-primary-dark">
            <Icon class="h-6 fill-current" :inline="true" icon="ant-design:star-filled"/>
            <span>4.6</span>
          </span>
        </nav>
        <nav class="md:ml-auto flex flex-wrap items-center text-base justify-center">
          <LanguagePicker class="mr-5"/>
          <router-link :to="{name: 'Home', hash: '#hero'}">
            <a class="mr-5 cursor-pointer"
               :class="inView === 'hero' ? 'text-primary-light hover:text-primary-dark'
                                         : 'hover:text-gray-900'">
              {{ $t('header.hero') }}</a>
          </router-link>
          <router-link :to="{name: 'Home', hash: '#features'}"
                       :class="inView === 'features' ?
                       'text-primary-light hover:text-primary-dark' : 'hover:text-gray-900'">
            <a class="mr-5 cursor-pointer" href="">
              {{ $t('header.features') }}</a>
          </router-link>
          <router-link :to="{name: 'Home', hash: '#reviews'}"
                       :class="inView === 'reviews' ? 'text-primary-light hover:text-primary-dark'
                                                 : 'hover:text-gray-900'">
            <a class="mr-5 cursor-pointer">
              {{ $t('header.reviews') }}</a>
          </router-link>
        </nav>
        <a class="inline-flex items-center hover:bg-primary-dark border-0 py-1 px-3
                       focus:outline-none text-white bg-primary-light rounded
                       text-base mt-4 md:mt-0"
           href="./menu.pdf" target="_blank">
          {{ $t('header.callToAction') }}
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
               stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </a>
      </div>
    </header>
    <div class="w-full text-center bg-primary-dark text-white"
      v-if="$t('header.news') !== 'header.news'">
      <h3 class="text-xl py-3">
        {{ $t('header.news') }}
      </h3>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import LanguagePicker from '@/components/LanguagePicker.vue';

const SECTIONS = [
  '#hero',
  '#features',
  '#reviews',
];

export default {
  name: 'Header',
  components: {
    LanguagePicker,
    Icon,
  },
  data() {
    return {
      inView: this.$route.name === 'Home' ? window.location.hash.substr(1) : '',
    };
  },
  methods: {
    pixelsVisibleOnScreen(selector) {
      const c = (window.innerHeight || document.documentElement.clientHeight);
      const boundingRect = document.querySelector(selector).getBoundingClientRect();
      const { y } = boundingRect;
      const h = boundingRect.height;
      if (y + h < 0) return 0;
      if (y < 0) return Math.max(h - y, 0);
      if (y + h <= c) return h;
      return Math.max(c - y, 0);
    },
    determineSelectorInView() {
      const index = SECTIONS.map(this.pixelsVisibleOnScreen)
        .reduce((iMax, x, i, arr) => (x > arr[iMax] ? i : iMax), 0);
      return SECTIONS[
        index
      ].substr(1);
    },
  },
  mounted() {
    document.addEventListener('scroll', () => {
      // Only do the highlighting if on Homepage
      this.inView = this.$route.name === 'Home' ? this.determineSelectorInView() : '';
    }, {
      passive: true,
    });
  },
};
</script>
