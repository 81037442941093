<template>
  <section>
    <div class="flex flex-col text-center w-full mb-5">
      <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900">
        {{ $t('reviews.title') }}
      </h1>
      <h2 class="text-sm text-primary-light tracking-widest font-medium title-font mb-1">
        {{ $t('reviews.subtitle') }}
      </h2>
    </div>
    <div class="px-5 h-full pt-5">
      <div class="flex flex-col gap-5">
        <div v-for="review in reviews" :key="review.name"
             class="flex flex-col border border-gray-300 border-2 rounded-xl p-4 gap-2">
          <div class="flex flex-row gap-2">
            <div>
              <img class="h-10"
                :src="review.url"/>
            </div>
            <div class="flex flex-col">
              <div class="flex flex-row justify-between gap-1 items-end">
                <span class="">{{review.name}}</span>
                <span class="font-extralight text-sm">via</span>
                <Icon icon="flat-color-icons:google" class="h-6"/>
              </div>
              <div class="flex flex-row text-yellow-300">
                <Icon v-for="i in review.rating" :key="i" class="h-6 fill-current" :inline="true"
                      icon="ant-design:star-filled"/>
              </div>
            </div>
          </div>
          <span class="text-sm font-light">{{review.text}}</span>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { Icon } from '@iconify/vue';

export default {
  name: 'SocialProof',
  components: {
    Icon,
  },
  data() {
    return {
      reviews: [
        {
          name: 'Ferdinand Biere',
          url: 'https://lh3.googleusercontent.com/a-/AOh14Ghhnwcotrc-4FFD4OMgVACgv1AqIKUudPUtsCwLQg=w72-h72-p-c0x00000000-rp-mo-ba5-br100',
          text: 'The iberico pork is absolutely to die for! Their fish is also amazing. One if not the best upscale restaurant in Friedenau.',
          rating: 5,
        },
        {
          name: 'Jonathan Kremer',
          url: 'https://lh3.googleusercontent.com/a/AATXAJxTvta2vbQ4bR-PpNxNnFxE4FXsZmvXuCba6pI=w72-h72-p-c0x00000000-rp-mo-br100',
          text: 'Ich bin bereits seit vielen Jahren Stammkunde bei Carlos und erwarte jeden Besuch mit großer Vorfreude. Das Essen ist für mich noch immer jedes Mal ein absolutes Highlight. Die Tageskarte sorgt für viel Abwechslung und überrascht immer wieder mit einer kreativen Vielfalt. Ich kenne nur sehr wenige Restaurants in Berlin, die einen Fisch in dieser exzellenten Qualität servieren. Die Bedienung ist total sympathisch und aufmerksam und vervollständigt das nette Ambiente und den angenehmen Charme in diesem tollen Restaurant.',
          rating: 5,
        },
        {
          name: 'Mrs. SNPL',
          url: 'https://lh3.googleusercontent.com/a-/AOh14Gi7yhjd7rNKtbGPqxL1TNXDGNk6mq3j_n3hbz4uAw=w72-h72-p-c0x00000000-rp-mo-ba4-br100',
          text: 'Eine absolute Freude für alle Geschmackssinne!\n'
            + 'Durch die Empfehlung unserer Freunde sind wir auf dieses zauberhafte Lokal gestoßen und zählen dieses nun zu unseren absoluten Favoriten. Die portugiesische Küche ist traditionell bodenständig und pur und die Zutaten schmecken hier noch genauso, wie sie sollten - authentisch und sind von höchster Qualität! Zudem ist der Service flott, aufmerksam und sehr herzlich!\n'
            + 'Das von außen unscheinbare Lokal ist recht klein aber sehr gemütlich und strahlt eine gewisse Romantik aus.\n'
            + 'Ich lege jedem ans Herz, vorab aber vorallem an den Wochenenden eine Reservierung zu buchen. Ansonsten wünsche ich allen einen guten Appetit :)\n',
          rating: 5,
        },
        {
          name: 'Marie R',
          url: 'https://lh3.googleusercontent.com/a/AATXAJwbknjO0-cvHiRcs5HDChg9hFA3yXqGM76ks0ci=w72-h72-p-c0x00000000-rp-mo-ba3-br100',
          text: 'Sehr leckeres Essen, schöne und gemütliche Atmosphäre mit toller Einrichtung. Auch der Wein ist zu empfehlen.',
          rating: 5,
        },
        {
          name: 'Sonja Hoopz',
          url: 'https://lh3.googleusercontent.com/a-/AOh14GhfgW30OJI4i_-ZAwbikOjvGZ2qb8JCT07WN_4Rlw=w72-h72-p-c0x00000000-rp-mo-ba4-br100',
          text: 'Das war fantastisch! Super lecker 🤤\n'
            + 'Tolles Ambiente und äußerst nette Bedienung👌🏽\n',
          rating: 5,
        },
      ],
    };
  },
};
</script>
