<template>
  <section class="min-w-screen min-h-screen text-gray-600 body-font">
    <div class="background-image absolute w-full h-full z-0 overflow-hidden flex">
      <div class="flex flex-col justify-center content-center">
        <div class="flex flex-col pr-10
                  mb-16 items-center text-center bg-white pl-10 py-5
                  md:rounded-r-2xl box">
          <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-primary-dark">
            {{ $t('hero.title') }}
            <br class="hidden lg:inline-block">{{ $t('hero.subtitle') }}
          </h1>
          <p class="leading-relaxed mb-4">{{ $t('hero.text') }}</p>
          <a class="px-2 py-1 flex-shrink-0 text-white bg-primary-light border-0 py-2 px-8
                    focus:outline-none hover:bg-primary-dark rounded text-lg sm:mt-0
                    cursor-pointer hover:bg-background-primary hover:text-primary-light delay-100
                    border border-primary-light border-3"
             href="./menu.pdf" target="_blank">
            {{ $t('hero.callToAction') }}</a>
        </div>
      </div>
      <div class="absolute z-50 bottom-0 right-6 flex flex-col invisible lg:visible">
        <img src="../assets/carlos.png" alt="person" class="max-h-screen self-end"
             style="min-height:50vh"/>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Hero',
  components: { },
};
</script>

<style scoped>
.background-image {
  background-image: url("../assets/img.png");
  background-repeat: no-repeat;
  background-color: #cccccc;
  background-position: center;
  background-size: cover;
}

.hero-image {
  position: relative;
}

.hero-image::before {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-image: url("../assets/img.png"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  filter: blur(3px);
}

.box {
  min-width: 100%;
}

section {
  height: 90%;
}
</style>
