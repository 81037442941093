<template>
<section class="">
  <div class="flex flex-col text-center w-full mb-5">
    <div align="center">
      <iframe src="https://player.vimeo.com/video/74821763?background=1&muted=1&autoplay=1"
                  allowfullscreen width="640" height="360"
                  frameborder="0" allow="autoplay; fullscreen" />
    </div>
  </div>
</section>
</template>

<script>
export default {
  name: 'VideoEmbed',
};
</script>

<style scoped>
iframe {
  max-width: 100%;
}
</style>
