export default {
  "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carlos Caravela®"])},
  "callToActionLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://form.typeform.com/to/hJ6fwxBn"])},
  "header": {
    "hero": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("companyName")])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratings"])},
    "callToAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue-Sat 17.30 - 24.00"])},
    "reservationRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advance reservation is recommended"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["030 / 852 26 60"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "footer": {
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
    "disclaimer": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["© 1997 - 2023 ", _linked("companyName"), " All rights reserved"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Made with ❤️   in Berlin"])}
  },
  "hero": {
    "title": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("companyName")])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A taste of Portugal in the Heart of Berlin since 1997"])},
    "callToAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Menu"])}
  },
  "reviews": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratings"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What our guests say about us"])}
  },
  "features": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What we offer to our guests"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugese Hospitality and Authentic Cuisine"])},
    "items": [
      {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fresh"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fresh fish daily and traditional Portuguese cuisine"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["emojione-monotone:pot-of-food"])}
      },
      {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homemade"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homemade portugese Tapas"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fluent:food-24-filled"])}
      },
      {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentic"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extensive collection of Portugal´s finest port and wines"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bxs:wine"])}
      }
    ]
  },
  "404": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404"])},
    "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are unable to find your page"])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To make it up, check out this cute cat :) image source"])},
    "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to Homepage"])}
  }
}