<template>
  <div class="bg-background-primary">
    <Header class="sticky top-0"/>
    <router-view/>
    <Footer/>
<!--    <div class="h-0 sticky bottom-0 h-screen z-50">-->
<!--      <cookie-banner/>-->
<!--    </div>-->
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
// import CookieBanner from '@/components/CookieBanner.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    // CookieBanner,
  },
  beforeCreate() {
    document.title = `${this.$t('companyName')} - ${this.$t('hero.title')}`;
  },
};
</script>
