<template>
  <footer class="text-gray-600 body-font">
    <div class="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
      <a class="flex title-font font-medium items-center md:justify-start justify-center
                text-gray-900">
        <img src="@/assets/logo/logo.png" class="h-10" alt="logo">
      </a>
      <a class="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2
                sm:mt-0 mt-4">
        {{ $t('footer.disclaimer') }}
        <router-link to="/imprint" class="px-1">
          <a class="text-gray-600 ml-1 underline">{{ $t('footer.imprint') }}</a>
        </router-link>
        <router-link to="/privacy" class="px-1 invisible">
          <a class="text-gray-600 ml-1 underline">{{ $t('footer.privacy') }}</a>
        </router-link>
      </a>
      <p class="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:py-2 sm:mt-0 mt-4">
        {{ $t('footer.note') }}</p>
      <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
        <a class="flex flex-row text-sm items-end cursor-pointer hover:underline"
           href="https://goo.gl/maps/S8VzaCztFZieYdWQ8" target="_blank">
          <Icon icon="fa6-solid:location-dot" class="h-6"/>
          <p class="ml-3">Dickhardtstraße 27, 12161 Berlin</p>
        </a>
    </span>
    </div>
  </footer>
</template>

<script>
import { Icon } from '@iconify/vue';

export default {
  name: 'Footer',
  components: {
    Icon,
  },
};
</script>
