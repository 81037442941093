<template>
  <div>
    <component :is="'script'" v-html="jsonld" type="application/ld+json"/>

    <Hero id="hero"/>
    <Features id="features"/>
    <VideoEmbed id="video"/>
    <SocialProof id="reviews"/>
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue';
import Features from '@/components/Features.vue';
import SocialProof from '@/components/SocialProof.vue';
import VideoEmbed from '@/components/VideoEmbed.vue';

import jsonld from '@/assets/jsonld.json';

export default {
  name: 'Home',
  components: {
    VideoEmbed,
    SocialProof,
    Features,
    Hero,
  },
  data() {
    return {
      jsonld: JSON.stringify(jsonld),
    };
  },
};
</script>
