<template>
<div>
  <select v-model="$root.$i18n.locale" class="cursor-pointer">
    <option v-for="(lang, i) in supportedLanguages" :key="`Lang${i}`" :value="lang.value">
      {{ lang.display }}
    </option>
  </select>
</div>
</template>

<script>
export default {
  name: 'LanguagePicker',
  data() {
    return {
      supportedLanguages: [
        {
          value: 'de',
          display: '🇩🇪',
        },
        {
          value: 'en',
          display: '🇬🇧',
        },
        {
          value: 'es',
          display: '🇪🇸',
        },
        {
          value: 'pt',
          display: '🇵🇹',
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
