<template>
  <section class="text-gray-600 body-font">
    <div class="container px-5 py-4 my-10 mx-auto">
      <div class="flex flex-col text-center w-full mb-20">
        <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900">
          {{ $t('features.title') }}
        </h1>
        <h2 class="text-sm text-primary-light tracking-widest font-medium title-font mb-1">
          {{ $t('features.subtitle') }}
        </h2>
      </div>
      <div class="flex flex-col md:flex-row justify-center align-middle">
        <div class="p-4 md:w-1/3 flex flex-col justify-items-center"
             v-for="i in numberOfFeatures" :key="i">
          <div class="flex rounded-lg bg-gray-100 p-8 flex-col my-auto h-52 md:h-50 lg:h-40
                      w-80 md:w-auto self-center">
            <div class="flex items-center mb-3">
              <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full
                          bg-primary-light text-white flex-shrink-0">
                <Icon :icon="$t('features.items[' + (i-1) + '].icon')" :inline="true"/>
              </div>
              <h2 class="text-gray-900 text-lg title-font font-medium">
                {{ $t('features.items[' + (i-1) + '].header') }}</h2>
            </div>
            <div class="flex-grow">
              <p class="leading-relaxed text-base">
                {{ $t('features.items[' + (i-1) + '].text') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Icon } from '@iconify/vue';

export default {
  name: 'Features',
  components: {
    Icon,
  },
  data() {
    return {
      numberOfFeatures: 3,
    };
  },
};
</script>

<style scoped>

</style>
