export default {
  "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carlos Caravela®"])},
  "callToActionLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://form.typeform.com/to/hJ6fwxBn"])},
  "header": {
    "hero": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("companyName")])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre Nosotros"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisiones"])},
    "callToAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menú"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martes - Sábado 17.30 - 24.00"])},
    "reservationRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se recomienda reservar con antelación"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["030 / 852 26 60"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "footer": {
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impreso"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protección de datos"])},
    "disclaimer": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["© 1997 - 2023 ", _linked("companyName"), " All rights reserved"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Made with ❤️   in Berlin"])}
  },
  "hero": {
    "title": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("companyName")])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El gusto de Portugal en el corazón de Berlín desde 1997"])},
    "callToAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver el Menú"])}
  },
  "reviews": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisiones"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo que nuestros huéspedes dicen de nosotros"])}
  },
  "features": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo que ofrecemos a nuestros huéspedes"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospitalidad portuguesa y cocina auténtica"])},
    "items": [
      {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fresco"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pescado fresco a diario y cocina tradicional portuguesa"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["emojione-monotone:pot-of-food"])}
      },
      {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casero"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapas portuguesas caseras"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fluent:food-24-filled"])}
      },
      {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auténtico"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amplia colección de los mejores oportos y vinos de Portugal"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bxs:wine"])}
      }
    ]
  },
  "404": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404"])},
    "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No podemos encontrar su página"])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para compensar, mira este lindo gato :) fuente de imagen"])},
    "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a la Página Principal"])}
  }
}