export default {
  "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carlos Caravela®"])},
  "callToActionLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "header": {
    "hero": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("companyName")])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über uns"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen"])},
    "callToAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speisekarte"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di-Sa 17.30 bis 24.00"])},
    "reservationRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorab Reservierung wird empfohlen"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["030 / 852 26 60"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "callToAction": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interesse? Reden Sie mit uns!"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt kontaktieren"])}
  },
  "footer": {
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über Uns"])},
    "disclaimer": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["© 1997 - 2023 ", _linked("companyName"), " Alle Rechte vorbehalten."])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Made with ❤️   in Berlin"])}
  },
  "hero": {
    "title": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("companyName")])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr original portugiesisches Restaurant seit 1997"])},
    "callToAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Speisekarte"])}
  },
  "reviews": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was unsere Gäste über uns sagen"])}
  },
  "features": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bieten unseren Gästen"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugiesische Gastfreundschaft und kulinarische Spezialitäten"])},
    "items": [
      {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisch"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein täglich frisches Angebot an Fisch und portugiesischen Spezialitäten"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["emojione-monotone:pot-of-food"])}
      },
      {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausgemacht"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausgemachte portugiesische Tapas"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fluent:food-24-filled"])}
      },
      {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentisch"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original portugiesische Weine"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bxs:wine"])}
      }
    ]
  },
  "404": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404"])},
    "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Seite finden wir leider nicht."])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Wiedergutmachung hier ein Katzenbild :) Bild Quelle"])},
    "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Homepage"])}
  }
}