import { createApp } from 'vue';
import VueCookieComply from 'vue-cookie-comply';
import 'vue-cookie-comply/dist/style.css';
import App from './App.vue';
import './assets/tailwind.css';
import router from './router';
import i18n from './i18n';

createApp(App)
  .use(i18n)
  .use(router)
  .use(VueCookieComply)
  .mount('#app');
